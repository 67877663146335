import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SliderImage } from '@shared/types/sliderImage.type';
import { AuthData, AuthUserData, Token } from '@auth/types/auth-data.type';
import { OrganizationService } from '@plan/organization/services/organization.service';

/**
 * @type Credentials holds the user credentials
 */
type Credentials = {
  email: string;
  password: string;
  password_confirmation?: string;
};
/**
 * @type EmailExistsResponse holds the response of the email existance check
 */
type EmailExistsResponse = {
  data: { email_exists: boolean };
  message: string;
};
/**
 * @type SliderImagesResponse holds the response of the slider images
 */
type SliderImagesResponse = {
  data: {
    slider: SliderImage[];
  };
  message: string;
};
/**
 * @type AuthDataResponse holds the response of the authentication
 */
export type AuthDataResponse = { data: AuthData; message: string };
/**
 * @type StatusResponse holds the response of status messages
 */
type StatusResponse = { data: any[]; message: string };
/**
 * @type disableAccountResponse holds the response of the disable account
 */
type disableAccountResponse = {
  data: {
    full_name: string;
    case_number: number;
  };
  message: string;
};
/**
 * Service that handles authentication, authorization and related functionality
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * @property Holds the storage type to localStorage or sessionStorage
   */
  private _storage!: Storage;
  /**
   * @property Holds the user data from the local storage or session storage based on user choice
   */
  private _userData!: AuthData | null;
  private _user: BehaviorSubject<AuthUserData> =
    new BehaviorSubject<AuthUserData>(this.userData?.user || null);

  /**
   * @constructor Initializes the service with HttpClient instance
   * @param _Http HttpClient
   * @param _Router Router
   */
  constructor(
    private _Http: HttpClient,
    private _Router: Router,
    private _OrganizationService: OrganizationService
  ) {}

  /**
   * @readonly Gets the storage type
   * @returns Storage
   */
  public get storage(): Storage {
    const storageType = localStorage.getItem('userData')
      ? localStorage
      : sessionStorage;

    return this._storage || storageType;
  }

  /**
   * @property Sets the user in the local storage or session storage based on user choice
   * @param value AuthData | null
   * @returns void
   */
  public set userData(value: AuthData | null) {
    if (!value) {
      this.storage.removeItem('userData');
      this._userData = null;
      return;
    }

    this.storage.setItem(
      'userData',
      JSON.stringify({
        ...value,
        token: { ...value.token, lastLoggedIn: Date.now() },
      })
    );
    this._userData = value;
    this._user.next(value.user);
  }

  setUser(user: AuthUserData) {
    this.userData = {
      token: { ...(this.userData.token as Token) },
      user: { ...this.userData.user, ...user },
    };
  }

  get user$() {
    return this._user.asObservable();
  }

  /**
   * @readonly Gets the user from the local storage or session storage based on user choice
   * @returns AuthData
   */
  public get userData(): AuthData {
    const userData = this.storage.getItem('userData') as string;
    return this._userData || JSON.parse(userData);
  }

  /**
   * @readonly Gets the user from the local storage or session storage
   * @returns Pick<User, 'id' | 'name' | 'email' | 'avatar'> | null
   */
  public get user(): AuthUserData {
    return this._user.value || this.userData?.user || null;
  }

  /**
   * @readonly Gets the user token from the local storage or session storage
   * @returns string
   */
  public get token(): Token | null {
    return this.userData?.token || null;
  }

  updateUser(user: any) {
    this.userData = {
      ...this.userData,
      user,
    };
  }

  /**
   * @method Fetches the slider images for the login page
   * @returns Observable<SliderImagesResponse>
   */
  public fetchLoginSliderData(): Observable<SliderImagesResponse> {
    const headers = new HttpHeaders().set('ngrok-skip-browser-warning', 'true');

    return this._Http.get<SliderImagesResponse>(
      `${environment.genericApi}sliders`,
      { headers }
    );
  }

  /**
   * @method Checks if an email is already registered in MICEtribe
   * @param email email address to check
   * @returns Observable<EmailExistsResponse>
   */
  public checkIfEmailExisted(email: string): Observable<EmailExistsResponse> {
    return this._Http.post<EmailExistsResponse>(
      `${environment.authApi}email-exists`,
      { email }
    );
  }

  /**
   * @method signIn using email and password
   * @param formValue {email: string, password: string}
   * @returns Observable<AuthDataResponse>
   */
  public signIn(formValue: Credentials): Observable<AuthDataResponse> {
    return this._Http.post<AuthDataResponse>(
      `${environment.authApi}sign-in`,
      formValue
    );
  }

  /**
   * @method Signup the user to MICEtribe system using the given credentials
   * @param formValue {email: string, password: string, password_confirmation: string}
   * @returns Observable<AuthDataResponse>
   */
  public signUp(formValue: Credentials): Observable<AuthDataResponse> {
    return this._Http.post<AuthDataResponse>(
      `${environment.authApi}sign-up`,
      formValue
    );
  }

  /**
   * @method Saves the user credentials in the local storage or session storage based user choice
   * user can choose if he clicks on remember me or not
   * @param type Storage
   * @param data user data to save
   * @returns void
   */
  public saveUserCredentials(
    type: Storage = sessionStorage,
    data: AuthData,
    route: boolean = true
  ): void {
    localStorage.setItem(
      'userData',
      JSON.stringify({
        ...data,
        token: { ...data.token, lastLoggedIn: Date.now() },
      })
    );

    if (type === localStorage) localStorage.setItem('remember_me', 'true');
    else localStorage.removeItem('remember_me');

    if (route) this._Router.navigate(['cross-road']);
  }

  /**
   * @method Signout the user from the system and clear the local storage or session storage
   * @returns Observable<StatusResponse>
   */
  public signOut(): Observable<StatusResponse> {
    return this._Http
      .post<StatusResponse>(`${environment.authApi}sign-out`, {})
      .pipe(
        map((response) => {
          this.clearUserCredentials();
          this._Router.navigate(['auth']);
          this._OrganizationService.currentOrganization = null;
          return response;
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }

  /**
   * @method Clear the user credentials from the local storage or session storage
   * @returns void
   */
  public clearUserCredentials(): void {
    this.userData = null;
    const tabsCount = localStorage.getItem('count');
    localStorage.clear();
    if(tabsCount) localStorage.setItem('count', tabsCount);
    sessionStorage.clear();
  }

  /**
   * @method Refreshes the user token if token is expired and saves it in the local storage or session storage
   * @returns Observable<AuthDataResponse>
   */
  public refreshToken(): Observable<AuthDataResponse | null> {
    return this._Http.post<AuthDataResponse>(
      `${environment.authApi}refresh-token`,
      {}
    );
  }

  /**
   * @method Sends a reset password email request to the given email address
   * @param formValue {email: string}
   * @returns Observable<StatusResponse>
   */
  public forgotPassword(formValue: {
    email: string;
  }): Observable<StatusResponse> {
    return this._Http.post<StatusResponse>(
      `${environment.authApi}forget-password`,
      formValue
    );
  }

  /**
   * @method resetPassword
   * @description Resets the password of the user using the given token
   * @param formValue { token: string, newPassword: string, newPasswordConfirmation: string}
   * @returns Observable<StatusResponse>
   */
  public resetPassword(formValue: {
    token: string;
    newPassword: string;
    newPasswordConfirmation: string;
  }): Observable<StatusResponse> {
    return this._Http.post<StatusResponse>(
      `${environment.authApi}reset-password`,
      formValue
    );
  }

  /**
   * @method injectGoogleScript
   * @description Injects the Google Auth library script to the head of the document if it's not already there
   * @returns void
   */
  public injectGoogleScript(): void {
    if (document.getElementById('google-auth-script')) return;

    const script = document.createElement('script');
    script.id = 'google-auth-script';
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    document.head.appendChild(script);
  }

  /**
   * @method signInWith
   * @description Signs in the user using the given provider and access token
   * @param provider accepts 'google' | 'linkedin'
   * @param accessToken accepts <access_token> for 'google' and <code> for 'linkedin'
   * @returns Observable<AuthData>
   */
  public signInWith(
    provider: 'google' | 'linkedin',
    accessToken: string
  ): Observable<AuthDataResponse> {
    const payload =
      provider === 'linkedin'
        ? { code: accessToken }
        : { access_token: accessToken };
    return this._Http.post<AuthDataResponse>(
      `${environment.authApi}sign-in/${provider}`,
      payload
    );
  }

  /**
   * @method signInWithGoogle
   * @description Handles Google Login button click event and init token client to login the user with google
   * @param handler callback function to handle the response from google
   * @returns void
   */
  public signInWithGoogle(handler: Function): void {
    const client = (window as any).google.accounts.oauth2.initTokenClient({
      client_id: environment.googleClientID,
      scope:
        'openid profile email https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/user.organization.read',
      auto_select: true,
      callback: handler,
    });

    client.requestAccessToken();
  }

  /**
   * @method grantEmailSendAccess
   * @description Grants the user access to send emails using Google
   * @param email The email address that should be automatically selected
   * @param handler Callback function to handle the response from Google
   * @returns void
   */
  public grantEmailSendAccess(email: string, handler: Function): void {
    const client = (window as any).google.accounts.oauth2.initTokenClient({
      client_id: environment.googleClientID,
      scope: 'https://www.googleapis.com/auth/gmail.send',
      auto_select: true,
      login_hint: email,
      callback: handler,
    });

    client.requestAccessToken();
  }

  /**
   * @TODO: Implement Unit test
   * @method signInWithLinkedIn
   * @description Handles LinkedIn Login button click event and opens a new window to login the user with LinkedIn
   * @returns void
   */
  public signInWithLinkedIn(): void {
    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${environment.linkedinClientID}&redirect_uri=${location.href}&scope=r_liteprofile%20r_emailaddress&prompt=login`;
    location.href = url;
  }

  /**
   * @method updateStatus
   * @description Updates the user status in the organization if the user is accepts the invitation
   * @param orgId organization id
   * @param status user status
   * @returns Observable<StatusResponse>
   */
  public updateStatus(
    orgId: number,
    status: string
  ): Observable<StatusResponse> {
    return this._Http.patch<StatusResponse>(
      `${environment.planApi}${orgId}/users/${this.user?.id}/update-status`,
      { status }
    );
  }

  /**
   * @method disableAccount
   * @description Disable user account
   * @param token returned from query params
   */
  public disableAccount(token: string): Observable<disableAccountResponse> {
    return this._Http.get<disableAccountResponse>(
      `${environment.rootApi}users/disable-account?token=${token}`
    );
  }
}
